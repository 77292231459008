.addMoreDirectorInnerDiv {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addMoreDirectorDiv {
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
}

.childAccordionContainer {
  width: 100%;
  margin-top: 30px;
}

.accordionImgContainer {
  display: flex;
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0 10px 0;
  width: 100%;
}

.mobileTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 0 30px;
}

.titleHeading {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: var(--font-family-medium);
  font-size: var(--font-size-h5);
  line-height: var(--font-line-height-default);
  color: var(--color-grey-700);
  margin: 0 0 20px 0;
  width: 85%;
}

.mobileTitleHeading {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: var(--font-family-medium);
  font-size: var(--font-size-h5);
  line-height: var(--font-line-height-default);
  color: var(--color-grey-700);
  margin: 0 0 20px 0;
  width: 85%;
}

.titleSubHeading {
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--color-grey-700);
  font-size: var(--font-size-b1);
  white-space: pre-line;
}

.mobileTitleSubHeading {
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--color-grey-700);
  width: 80%;
}

.directorForm {
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 6px 0 0 0;
}

.mobileDirectorForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 6px 0 0 0;
}

.accordionHeaderLeft {
  width: calc(100% - 50px);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}
@media (min-width:768px) {
  .titleHeading {
      font-size: var(--font-size-h3);
    } 
  }