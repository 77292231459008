.label {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  display: flex;
  justify-content: center;
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
}

.span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #D8DBE0;
  transition: 0.3s;
  border-radius: 20px;
}

.span:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 1px;
  bottom: 1.29px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.input:checked+.span {
  background-color: #90B400;
}

.input:checked+.span:before {
  transform: translateX(19px);
}

.strong {
  margin-left: 12px;
  font-size: 13px;
  display: flex;
  height: 22px;
  align-items: center;
}

.container {
  display: flex;
}