/* Font weights */
.font-light {
  font-weight: var(--font-weight-light);
}

.font-regular {
  font-weight: var(--font-weight-regular);
}

.font-medium {
  font-weight: var(--font-weight-medium);
}

.font-bold {
  font-weight: var(--font-weight-bold);
}

.font-black {
  font-weight: var(--font-weight-black);
}