.myApplications {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.contentSection {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.containerLeft {
  width: 100%;
  max-width: 343px;
  margin-top: 38px;
  height: inherit;
}

.containerRight {
  flex-grow: 1;
  width: calc(100% - 343px);
  display: flex;
  justify-content: center;
  min-width: 60%;
}

.rightWrapper {
  display: flex;
  flex-direction: column;
  width: 95%;
  align-items: center;
  margin-bottom: 80px;
}

.rightHeading {
  text-align: center;
  width: 100%;
  margin-top: 58px;
  font-family: var(--font-family-medium);
  line-height: 150%;
  letter-spacing: -0.9px;
  font-size: var(--font-size-h3);
}

.rightSubHeading {
  width: 100%;
  margin-top: 20px;
  line-height: 150%;
  font-size: var(--font-size-h6);
}

.accordionSection {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carouselContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
}

.carouselHeading {
  font-size: var(--font-size-h4);
  line-height: 150%;
  margin-bottom: 20px;
}

.headerTitle {
  font-weight: 700;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .rightHeading {
    text-align: left;
  }
}
