/* Margin */
.ma-1 {
  margin: var(--spacing-xs1);
}

.ma-2 {
  margin: var(--spacing-xs2);
}

.ma-3 {
  margin: var(--spacing-s1);
}

.ma-4 {
  margin: var(--spacing-s2);
}

.ma-5 {
  margin: var(--spacing-m);
}

.ma-6 {
  margin: var(--spacing-l1);
}

.ma-7 {
  margin: var(--spacing-l2);
}

.ma-8 {
  margin: var(--spacing-xl);
}

.mx-1 {
  margin-left: var(--spacing-xs1);
  margin-right: var(--spacing-xs1);
}

.mx-2 {
  margin-left: var(--spacing-xs2);
  margin-right: var(--spacing-xs2);
}

.mx-3 {
  margin-left: var(--spacing-s1);
  margin-right: var(--spacing-s1);
}

.mx-4 {
  margin-left: var(--spacing-s2);
  margin-right: var(--spacing-s2);
}

.mx-5 {
  margin-left: var(--spacing-m);
  margin-right: var(--spacing-m);
}

.mx-6 {
  margin-left: var(--spacing-l1);
  margin-right: var(--spacing-l1);
}

.mx-7 {
  margin-left: var(--spacing-l2);
  margin-right: var(--spacing-l2);
}

.mx-8 {
  margin-left: var(--spacing-xl);
  margin-right: var(--spacing-xl);
}

.my-1 {
  margin-top: var(--spacing-xs1);
  margin-bottom: var(--spacing-xs1);
}

.my-2 {
  margin-top: var(--spacing-xs2);
  margin-bottom: var(--spacing-xs2);
}

.my-3 {
  margin-top: var(--spacing-s1);
  margin-bottom: var(--spacing-s1);
}

.my-4 {
  margin-top: var(--spacing-s2);
  margin-bottom: var(--spacing-s2);
}

.my-5 {
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
}

.my-6 {
  margin-top: var(--spacing-l1);
  margin-bottom: var(--spacing-l1);
}

.my-7 {
  margin-top: var(--spacing-l2);
  margin-bottom: var(--spacing-l2);
}

.my-8 {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

.mr-1 {
  margin-right: var(--spacing-xs1);
}

.mr-2 {
  margin-right: var(--spacing-xs2);
}

.mr-3 {
  margin-right: var(--spacing-s1);
}

.mr-4 {
  margin-right: var(--spacing-s2);
}

.mr-5 {
  margin-right: var(--spacing-m);
}

.mr-6 {
  margin-right: var(--spacing-l1);
}

.mr-7 {
  margin-right: var(--spacing-l2);
}

.mr-8 {
  margin-right: var(--spacing-xl);
}

.ml-1 {
  margin-left: var(--spacing-xs1);
}

.ml-2 {
  margin-left: var(--spacing-xs2);
}

.ml-3 {
  margin-left: var(--spacing-s1);
}

.ml-4 {
  margin-left: var(--spacing-s2);
}

.ml-5 {
  margin-left: var(--spacing-m);
}

.ml-6 {
  margin-left: var(--spacing-l1);
}

.ml-7 {
  margin-left: var(--spacing-l2);
}

.ml-8 {
  margin-left: var(--spacing-xl);
}

.mt-1 {
  margin-top: var(--spacing-xs1);
}

.mt-2 {
  margin-top: var(--spacing-xs2);
}

.mt-3 {
  margin-top: var(--spacing-s1);
}

.mt-4 {
  margin-top: var(--spacing-s2);
}

.mt-5 {
  margin-top: var(--spacing-m);
}

.mt-6 {
  margin-top: var(--spacing-l1);
}

.mt-7 {
  margin-top: var(--spacing-l2);
}

.mt-8 {
  margin-top: var(--spacing-xl);
}

.mb-1 {
  margin-bottom: var(--spacing-xs1);
}

.mb-2 {
  margin-bottom: var(--spacing-xs2);
}

.mb-3 {
  margin-bottom: var(--spacing-s1);
}

.mb-4 {
  margin-bottom: var(--spacing-s2);
}

.mb-5 {
  margin-bottom: var(--spacing-m);
}

.mb-6 {
  margin-bottom: var(--spacing-l1);
}

.mb-7 {
  margin-bottom: var(--spacing-l2);
}

.mb-8 {
  margin-bottom: var(--spacing-xl);
}

.ma-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
