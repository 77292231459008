.item {
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-m) 0;
  border-top: 1px solid var(--color-grey-300);
  gap: var(--spacing-m);
}

.title {
  flex: 1;
}

.content {
  margin-bottom: var(--spacing-m);
}

.arrow {
  width: 15px;
  height: 20px;
}

@media screen and (min-width: 768px) {
  .arrow {
    padding: 0 var(--spacing-m);
  }
}
