.section {
  padding: var(--spacing-m) var(--gutter-mobile);
}

@media screen and (min-width: 768px) {
  .section {
    padding: var(--spacing-m) var(--gutter-tablet);
  }
}

@media screen and (min-width: 1440px) {
  .section {
    padding: var(--spacing-m) var(--gutter-desktop);
    max-width: var(--container-max-width);
  }
}