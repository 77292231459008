/* Border-radius */
.rounded-xs {
  border-radius: var(--radius-xs);
}

.rounded-s {
  border-radius: var(--radius-s);
}

.rounded-m {
  border-radius: var(--radius-m);
}

.rounded-l {
  border-radius: var(--radius-l);
}

.rounded-xl {
  border-radius: var(--radius-xl);
}