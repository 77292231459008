.typography {
  line-height: var(--font-line-height-default);
  padding: 0;
  margin: 0 0 var(--spacing-m);
}

h1, .h1 {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-h1);
}

h2, .h2 {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-h2);
}

h3, .h3 {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-h3);
}

h4, .h4 {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-h4);
}

h5, .h5 {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-h5);
}

h6, .h6 {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-h6);
}

.b1 {
  font-size: var(--font-size-b1);
}

.b2 {
  font-size: var(--font-size-b2);
}

.b3 {
  font-size: var(--font-size-b3);
}

.weight-light {
  font-weight: var(--font-weight-light);
}

.weight-regular {
  font-weight: var(--font-weight-regular);
}

.weight-medium {
  font-weight: var(--font-weight-medium);
}

.weight-bold {
  font-weight: var(--font-weight-bold);
}

.weight-black {
  font-weight: var(--font-weight-black);
}

.family-light {
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-light);
}

.family-regular {
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family);
}

.family-medium {
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-medium);
}

.family-bold {
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-bold);
}

.family-black {
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-black);
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-justify {
  text-align: justify;
}

.inline {
  display: inline;
}

.mb-1 {
  margin-bottom: var(--spacing-xs1);
}

.mb-2 {
  margin-bottom: var(--spacing-xs2);
}

.mb-3 {
  margin-bottom: var(--spacing-s1);
}

.mb-4 {
  margin-bottom: var(--spacing-s2);
}

.mb-5 {
  margin-bottom: var(--spacing-m);
}

.mb-6 {
  margin-bottom: var(--spacing-l1);
}

.mb-7 {
  margin-bottom: var(--spacing-l2);
}

.mb-8 {
  margin-bottom: var(--spacing-xl);
}


@media (max-width: 576px) {
  h3, .h3 {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-h5);
  }
}