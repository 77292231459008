:root {
  /* Colors - Greyscale */
  --color-white: #FFFFFF;
  --color-grey-025: #F5F6F7;
  --color-grey-50: #EBEDEF;
  --color-grey-100: #D8DBE0;
  --color-grey-200: #C9CCD4;
  --color-grey-300: #A8ACB8;
  --color-grey-400: #8A8D9C;
  --color-grey-500: #6D6F81;
  --color-grey-600: #525365;
  --color-grey-700: #393949;
  --color-grey-800: #222739;
  --color-grey-900: #141827;
  --color-black: #08081B;

  /* Colors - Primary */
  --color-red-020: #FACCCC;
  --color-red-040: #F59999;
  --color-red-060: #F06666;
  --color-red-100: #E60000;
  --color-red-200: #BD0000;
  --color-red-300: #990000;

  /* Colors - Accent */
  --color-blue-020: #CCEFF4;
  --color-blue-040: #99DFEA;
  --color-blue-060: #66D0DF;
  --color-blue-100: #00B0CA;
  --color-blue-200: #007E92;
  --color-blue-300: #005765;
  --color-blue-400: #003D47;
  --color-purple-020: #EBD4EC;
  --color-purple-040: #D7AAD9;
  --color-purple-060: #C47FC6;
  --color-purple-100: #9C2AA0;
  --color-purple-200: #5E2750;

  /* Colors - Status */
  --color-green-020: #E9F0CC;
  --color-green-040: #D3E199;
  --color-green-060: #BCD266;
  --color-green-100: #90B400;
  --color-green-200: #428600;
  --color-yellow-020: #FFF5CC;
  --color-yellow-040: #FFEA99;
  --color-yellow-060: #FEE066;
  --color-yellow-100: #FECB00;
  --color-orange-100: #EB9700;

  /* Font families */
  --font-family-light: RobotoLight;
  --font-family: Roboto;
  --font-family-medium: RobotoMedium;
  --font-family-bold: RobotoBold;
  --font-family-black: RobotoBlack;

  /* Font line-heights */
  --font-line-height-default: 150%;

  /* Font sizes */
  --font-size-h1: 3.75rem; /* 60px */
  --font-size-h2: 3rem; /* 48px */
  --font-size-h3: 2rem; /* 32px */
  --font-size-h4: 1.625rem; /* 26px */
  --font-size-h5: 1.375rem; /* 22px */
  --font-size-h6: 1.125rem; /* 18px */
  --font-size-b1: 1rem; /* 16px */
  --font-size-b2: 0.875rem; /* 14px */
  --font-size-b3: 0.75rem; /* 12px */

  /* Font weights */
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  /* Breakpoints */
  --breakpoint-mobile: 22.5rem; /* 360px */
  --breakpoint-tablet: 48rem; /* 768px */
  --breakpoint-desktop: 90rem; /* 1440px */

  /* Max Width */
  --container-max-width: 120rem; /* 1920px */

  /* Gutters */
  --gutter-mobile: 1rem; /* 16px */
  --gutter-tablet: 2rem; /* 32px */
  --gutter-desktop: 4.875rem; /* 78px */

  /* Shadows */
  --shadow-default: 0px 5px 20px 0px rgba(12, 12, 26, 0.15);
  --shadow-hover: 0px 5px 15px 0px rgba(12, 12, 26, 0.35);
  --shadow-inverse: 0px -5px 20px 0px rgba(12, 12, 26, 0.15);

  /* Spacing */
  --spacing-xs1: 0.25rem; /* 4px */
  --spacing-xs2: 0.5rem; /* 8px */
  --spacing-s1: 0.75rem; /* 12px */
  --spacing-s2: 1rem; /* 16px */
  --spacing-m: 1.5rem; /* 24px */
  --spacing-l1: 2rem; /* 32px */
  --spacing-l2: 2.5rem; /* 40px */
  --spacing-xl: 4rem; /* 64px */

  /* Radius */
  --radius-xs: 0.25rem; /* 4px */
  --radius-s: 0.5rem; /* 8px */
  --radius-m: 1rem; /* 16px */
  --radius-l: 1.5rem; /* 24px */
  --radius-xl: 2rem; /* 32px */
}
