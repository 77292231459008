/* Font sizes - Body */
.body-1 {
  font-size: var(--font-size-b1);
}

.body-2 {
  font-size: var(--font-size-b2);
}

.body-3 {
  font-size: var(--font-size-b3);
}
