/* Padding */
.pa-1 {
  padding: var(--spacing-xs1);
}

.pa-2 {
  padding: var(--spacing-xs2);
}

.pa-3 {
  padding: var(--spacing-s1);
}

.pa-4 {
  padding: var(--spacing-s2);
}

.pa-5 {
  padding: var(--spacing-m);
}

.pa-6 {
  padding: var(--spacing-l1);
}

.pa-7 {
  padding: var(--spacing-l2);
}

.pa-8 {
  padding: var(--spacing-xl);
}

.px-1 {
  padding-left: var(--spacing-xs1);
  padding-right: var(--spacing-xs1);
}

.px-2 {
  padding-left: var(--spacing-xs2);
  padding-right: var(--spacing-xs2);
}

.px-3 {
  padding-left: var(--spacing-s1);
  padding-right: var(--spacing-s1);
}

.px-4 {
  padding-left: var(--spacing-s2);
  padding-right: var(--spacing-s2);
}

.px-5 {
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);
}

.px-6 {
  padding-left: var(--spacing-l1);
  padding-right: var(--spacing-l1);
}

.px-7 {
  padding-left: var(--spacing-l2);
  padding-right: var(--spacing-l2);
}

.px-8 {
  padding-left: var(--spacing-xl);
  padding-right: var(--spacing-xl);
}

.py-1 {
  padding-top: var(--spacing-xs1);
  padding-bottom: var(--spacing-xs1);
}

.py-2 {
  padding-top: var(--spacing-xs2);
  padding-bottom: var(--spacing-xs2);
}

.py-3 {
  padding-top: var(--spacing-s1);
  padding-bottom: var(--spacing-s1);
}

.py-4 {
  padding-top: var(--spacing-s2);
  padding-bottom: var(--spacing-s2);
}

.py-5 {
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-m);
}

.py-6 {
  padding-top: var(--spacing-l1);
  padding-bottom: var(--spacing-l1);
}

.py-7 {
  padding-top: var(--spacing-l2);
  padding-bottom: var(--spacing-l2);
}

.py-8 {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
}

.pt-1 {
  padding-top: var(--spacing-xs1);
}

.pt-2 {
  padding-top: var(--spacing-xs2);
}

.pt-3 {
  padding-top: var(--spacing-s1);
}

.pt-4 {
  padding-top: var(--spacing-s2);
}

.pt-5 {
  padding-top: var(--spacing-m);
}

.pt-6 {
  padding-top: var(--spacing-l1);
}

.pt-7 {
  padding-top: var(--spacing-l2);
}

.pt-8 {
  padding-top: var(--spacing-xl);
}

.pb-1 {
  padding-bottom: var(--spacing-xs1);
}

.pb-2 {
  padding-bottom: var(--spacing-xs2);
}

.pb-3 {
  padding-bottom: var(--spacing-s1);
}

.pb-4 {
  padding-bottom: var(--spacing-s2);
}

.pb-5 {
  padding-bottom: var(--spacing-m);
}

.pb-6 {
  padding-bottom: var(--spacing-l1);
}

.pb-7 {
  padding-bottom: var(--spacing-l2);
}

.pb-8 {
  padding-bottom: var(--spacing-xl);
}

.pl-1 {
  padding-left: var(--spacing-xs1);
}

.pl-2 {
  padding-left: var(--spacing-xs2);
}

.pl-3 {
  padding-left: var(--spacing-s1);
}

.pl-4 {
  padding-left: var(--spacing-s2);
}

.pl-5 {
  padding-left: var(--spacing-m);
}

.pl-6 {
  padding-left: var(--spacing-l1);
}

.pl-7 {
  padding-left: var(--spacing-l2);
}

.pl-8 {
  padding-left: var(--spacing-xl);
}

.pr-1 {
  padding-right: var(--spacing-xs1);
}

.pr-2 {
  padding-right: var(--spacing-xs2);
}

.pr-3 {
  padding-right: var(--spacing-s1);
}

.pr-4 {
  padding-right: var(--spacing-s2);
}

.pr-5 {
  padding-right: var(--spacing-m);
}

.pr-6 {
  padding-right: var(--spacing-l1);
}

.pr-7 {
  padding-right: var(--spacing-l2);
}

.pr-8 {
  padding-right: var(--spacing-xl);
}
