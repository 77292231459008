.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  color: var(--color-grey-700);
  max-width: 400px;
}

.confirmation {
  color: var(--color-grey-700);
  margin-top: 20px;
}