.chip {
  color: var(--color-white);
  border-radius: var(--radius-l);
  background-color: var(--color-grey-200);
  padding: var(--spacing-xs1) var(--spacing-s2) var(--spacing-xs1) var(--spacing-l1);
  display: inline-block;
  position: relative;
}

.chip::before {
  content: '\00B7';
  font-size: var(--spacing-l1);
  color: var(--color-white);
  position: absolute;
  left: var(--spacing-s1);
}

.purple {
  background-color: var(--color-purple-100);
}

.orange {
  background-color: var(--color-orange-100);
}

.blue {
  background-color: var(--color-blue-100);
}

.green {
  background-color: var(--color-green-200);
}

.black {
  background-color: var(--color-black);
}

.yellow {
  background-color: var(--color-yellow-100);
}

.red {
  background-color: var(--color-red-100);
}