.financialDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 23px;
  width: 85% !important;
  background-color:'red'
}

.titleHeading {
  margin: 0 0 20px;
}
