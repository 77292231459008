.uploadContainer {
  width: 90vw;
  max-width: 800px;
  min-height: 211px;
  height: auto;
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid var(--color-grey-700);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.heading {
  width: 90%;
  margin: 20px 0px;
  font-size: 20px;
  font-weight: 400;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 23px;
  width: 85% !important;
  background-color:'red'
}

.fileUploadContainer {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 62px;
  cursor: pointer;
}

.pageSubHeading {
  color: var(--color-grey-700);
  font-size: var(--font-size-b1);
  line-height: var(--font-line-height-default);
  text-align: center;
  margin: 0 1vw;
  width: 85%;
}

.pageBottomTxtContainer {
  color: var(--color-grey-700);
  width: 95%;
  margin-top: 28px;
  display: flex;
  text-align: center;
  font-size: var(--font-size-b2);
  line-height: var(--font-line-height-default);
  flex-direction: column;
}

.docInfoBoldTxt {
  font-weight: var(--font-family-bold);
}

.docInfoContainer {
  display: block;
  flex-direction: row;
  margin: 10px;
  justify-content: space-between;
  width: 100%;
}

.sizeFormat {
  color: var(--color-grey-700);
  font-size: var(--font-size-b3);
  line-height: var(--font-line-height-default);
  display: flex;
  flex-direction: column;
  width: 130px;
}

.docInfo {
  font-size: var(--font-size-b3);
  line-height: var(--font-line-height-default);
  display: flex;
  flex-direction: row;
  float: left;
  width: calc(100% - 135px);
  margin-right: 5px;
}

.docInfoMobile {
  width: 100%;
}

.boldText {
  font-weight: bold;
}

.custDocDotContainer {
  margin-top: 5px;
  margin-right: 5px;
  width: 3px;
}

.custDocDot {
  border-radius: 50%;
  background: var(--color-grey-700);
  height: 3px;
  width: 3px;
  margin-top: 2px;
}

.documentCardContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 800px;
}

.errorMessage {
  width: 90%;
  font-size: 12px;
  margin-bottom: 10px;
  color: var(--color-red-200);
}

.titleHeading {
  margin: 0 0 20px;
}
