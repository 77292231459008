/* Box shadows */
.shadow-default {
  box-shadow: var(--shadow-default);
}

.shadow-hover {
  box-shadow: var(--shadow-hover);
}

.shadow-inverse {
  box-shadow: var(--shadow-inverse);
}