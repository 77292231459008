.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 230px;
  margin-top: 60px;
}
.title {
  font-family: var(--font-family-medium);
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 20px;
}
.statementList {
  margin: 0px;
  margin-bottom: 50px;
  line-height: 24px;
  padding: 0px;
  color: var(--color-black);
  font-weight: var(--font-weight-black);
}
.questionSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 90%;
  align-items: center;
}
.questionText {
  color: var(--color-grey-600);
  margin-bottom: 5px;
}


@media screen and (max-width: 576px) {
  .statementList{
    width: 80%;
    margin-left: 20px;
  }
}