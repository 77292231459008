.mainContainer {
  background: var(--color-white);
  width: 100%;
  max-width: 686px;
  height: 100%;
  min-height: 450px;
  max-height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around
}

.heading {
  font-size: 1.88em;
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-default);
  letter-spacing: 0.9;
  text-align: center;
}

.subHeading {
  color: var(--color-grey-400);
  font-size: var(--font-size-h6);
  line-height: var(--font-line-height-default);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width:63%;
}

.subHeadingHiddenMsisdn {
  color: var(--color-grey-900);
  font-weight: var(--font-weight-bold);
  font-size: 1.13em;
  letter-spacing: 0.36;

}

.otpEntryContainer {
  display: flex;
  flex-direction: row;
  max-width: 520px;
  width: 100%;
  justify-content: space-around;
  height: 70px
}

.otpEntryBox {
  width: 13%;
  height: 70px;
  background: var(--color-grey-025);
  text-align: center;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 1.5em;
  line-height: var(--font-line-height-default);
  font-weight: var(--font-weight-medium);
  border-radius: 4px;
  padding: 2px;
}

.otpEntryBoxValue {
  background: var(--color-grey-200);
  border-bottom: 3px solid var(--color-red-100);
}

.attemptStep {
  display: flex;
  justify-content: center;
  font-size: 0.88em;
  color: var(--color-grey-900);
  line-height: var(--font-line-height-default);
}

.warningImg {
  width: 30px;
  height: 30px;
  background-color: var(--color-orange-100);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
}

.exclamationLine {
  height: 11.25px;
  width: 2.8px;
  background: var(--color-white);
  border-radius: 4
}

.exclamationDot {
  height: 3.75px;
  width: 3.75px;
  background: var(--color-white);
  border-radius: 50%;
  margin-top: 3px
}

.incorrectOtpMsg {
  margin-left: 10px;
}

.continueBtn {
  width: 50%;
  max-width: 352px;
  height: 50px;
  min-height: 50px;
  font-size : var(--font-size-b1)
}

.continueBtnDisabled {
  width: 50%;
  max-width: 352px;
  min-height: 50px;
  color: var(--color-grey-400);
  opacity: 0.7;
  font-weight: var(--font-weight-medium)
}

.resendOtpText {
  font-weight: var(--font-weight-medium)
}

.resendLink {
  font-weight: var(--font-weight-bold);
  color: var(--color-red-100);
  line-height: 140%;
  margin-left: 5px;
  cursor: pointer
}

.needAssistanceBlock {
  display: flex;
  align-items: center;
  flex-direction: column
}

.needAssistanceTxt {
  color: var(--color-grey-600);
  font-weight: var(--font-weight-bold)
}

.contactUsLink {
  color: var(--color-grey-700);
  width: 78%;
  text-align: center;
  margin-top: 7px
}

.attemptsTextContainer {
  display: flex;
  align-items: center;
}

.attemptsTextWrapper{
  display: flex;
  flex-direction: column;
}

.successIcon {
  width: 30px;
  height: 30px;
}

.incorrectOtpBlockedMsg {
  margin-top: 10px;
}

.incorrectOtpTimeout{
  font-size: 22px;
  font-weight: 700;
  color: #333333;
  margin-top: 10px;
}

@media screen and (max-width: 576px) {

  .continueBtn {
    width: auto;
  }

  .continueBtnDisabled {
    width: auto;
  }
  .heading {
    font-size: var(--font-size-h5);
  }
  .subHeading {
    color: var(--color-grey-400);
    font-size: var(--font-size-b2);
    line-height: var(--font-line-height-default);
    width:100%;
   }
   .otpEntryContainer {
    height: 45px;
  }
  .attemptStep {
    font-size: var(--font-size-b2);
    color: var(--color-grey-900);
  }
  .otpEntryBox{
    height: 45px;
  }
}