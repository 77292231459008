.faqList {
  border: 1px solid var(--color-grey-200);
  border-radius: var(--radius-m);
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--spacing-m);
}

.title {
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-medium);
  margin: var(--spacing-s1) var(--spacing-l1) var(--spacing-m);
}
