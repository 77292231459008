@import "_variables.css";
@import "_body.css";
@import "_heading.css";
@import "_shadow.css";
@import "_bg.css";
@import "_font.css";
@import "_color.css";
@import "_text.css";
@import "_rounded.css";
@import "_p.css";
@import "_m.css";
@import "_container.css";

body {
  font-family: var(--font-family), sans-serif;
  line-height: var(--font-line-height-default);
  font-size: var(--font-size-b1);
  font-weight: var(--font-weight-regular);
  color: var(--color-black);
  background-color: var(--color-white);
  margin: 0;
  padding: 0;
}

select {
  font-size: var(--font-size-b1);
}

a {
  color: var(--color-red-100);
}

input:-webkit-autofill{
  -webkit-background-clip: text;
}
