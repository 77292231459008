.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0 10px 0;
  width: 100%;
}

.titleHeading {
  display: flex;
  color: var(--color-grey-700);
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-h5);
  font-family: var(--font-family-medium);
  line-height: var(--font-line-height-default);
  margin: 0 0 20px 0;
  width: 85%;
}

.titleSubHeading {
  display: flex;
  color: var(--color-grey-700);
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-b1);
  line-height: var(--font-line-height-default);
  width: 85%;
  white-space: pre-line;
}
@media (min-width: 768px) {
  .titleHeading {
    font-size: var(--font-size-h3);
  } 
}
