.title {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-medium);
  text-align: center;
  line-height: 150%;
}

.list {
  padding: 0;
}

.list > * {
  list-style-position: inside;
}

.row {
  display: flex;
  justify-content: space-between;
}

.accordion {
  padding: var(--spacing-m);
}

.center {
  text-align: center;
}

.url {
  color: var(--color-red-100);
  text-decoration: underline;
  cursor: pointer;
}

.container {
  position: relative;
  margin-bottom: 100px;
}