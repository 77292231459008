.titleHeading {
  color: var(--color-grey-700);
}

.loginPage {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 167px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
}

.titleSubHeading {
  color: var(--color-grey-700);
  width: 80%;
  white-space: pre-line;
  margin-top: 30px;
}

.titleSubHeading2 {
  color: var(--color-grey-700);
  margin-top: 15px;
}

.boldLoginLink {
  color: var(--color-red-100);
  cursor: pointer;
  font-family: var(--font-family-medium);
}

.termsAndPolicy {
  margin-top: 57px;
  text-align: center;
  width: 90%;
  color: var(--color-grey-500);
  font-size: var(--font-size-b2);
}

.bottomLink {
  color: var(--color-red-100);
  cursor: pointer;
}

.consent {
  padding: var(--spacing-m) 0;
  display: flex;
  gap: var(--spacing-m);
  justify-content: space-evenly;
  font-size: var(--font-size-b2);
}
/* 
.consent > :first-child {
  flex: 0 1 auto;
}

.consent > :last-child {
  flex: 1;
} */

