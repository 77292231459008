/* IOS Safari v14 and up no longer support custom scrollbar */
@supports selector(::-webkit-scrollbar)
  and selector(::-webkit-scrollbar-track)
  and selector(::-webkit-scrollbar-thumb)
{
  /* width */
  .tableScrollBarStyle::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  .tableScrollBarStyle::-webkit-scrollbar-track {
    background: #A6A9AF;
    margin-top: 48px;
    margin-bottom: 5px;
    border-radius: 2.5px;
  }

  /* Handle */
  .tableScrollBarStyle::-webkit-scrollbar-thumb {
    background: #E60000;
    border-radius: 2.5px;
  }

  @media (max-width: 576px) {
    .tableScrollBarStyle::-webkit-scrollbar-track {
      margin-top: 50px;
    }
  }
}
