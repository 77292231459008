.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
  }
  
  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0 10px 0;
    width: 100%;
  }
  
  .mobileTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 0 30px;
  }
  
  .titleHeading {
    color: var(--color-grey-700);
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-size: var(--font-size-h3);
    font-family: var(--font-family-medium);
    line-height: var(--font-line-height-default);
    margin: 0 0 20px 0;
    width: inherit;
  }
  
  .mobileTitleHeading {
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-size: var(--font-size-h5);
    font-family: var(--font-family-medium);
    line-height: var(--font-line-height-default);
    margin: 0 0 20px 0;
    width: inherit;
  }
  
  .titleSubHeading {
    color: var(--color-grey-700);
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-size: var(--font-size-b1);
    line-height: var(--font-line-height-default);
    width: inherit;
    white-space: pre-line;
  }
  
  .formFieldRadioContainer {
    display: flex;
    justify-content: left;
    margin: 12px 0 10px 0;
    width: 32.6rem;
    max-width: 100%;
  }
  
  .subTextMargin {
    margin-bottom: 20px;
  }
  