.main {
  position: relative;
  margin-bottom: 100px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: var(--spacing-l1) var(--spacing-m);
  gap: var(--spacing-m);
}

.sliderHeader {
  text-align: center;
  margin-bottom: var(--spacing-m);
  font-size: var(--font-size-h5);
}

.summaryList {
  margin-bottom: var(--spacing-m);
}

.rangeSlider {
  margin-bottom: var(--spacing-m);
}

.rangeSliderAmount {
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-100);
  display: block;
}

.title {
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-medium);
}

.footer {
  position: sticky;
  bottom: 0;
  background-color: var(--color-grey-800);
  padding: var(--spacing-s2);
}

/* TODO: Clean this up */

.summaryContainer {
  padding: 0 var(--spacing-m);
}

.summaryProgressBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  flex-grow: 1;
}

.summaryProgressBarInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 0 0;
}

.summarySubContainerInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 270px;
  flex-grow: 2;
}

.progressIndicator {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}

.blueEllipse {
  height: 10px;
  width: 10px;
  border-radius: 20px;
  background: var(--color-blue-100);
  margin: 0 10px 0 0;
}

.redEllipse {
  height: 10px;
  width: 10px;
  border-radius: 20px;
  background: var(--color-red-100);
  margin: 0 5px 0 0;
}

.progressLabel {
  display: flex;
  flex-direction: column;
  color: var(--color-grey-600);
  font-size: var(--font-size-b2);
}

.progressLabelAmt {
  font-size: var(--font-size-b2);
  color: var(--color-grey-600);
  font-weight: var(--font-weight-medium);
}

.description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.descriptionLabel {
  color: var(--color-grey-600);
}

.descriptionValue {
  color: var(--color-grey-600);
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 184px);
  word-break: break-all;
}

.arrearsAmt {
  line-height: 24px;
  color: var(--color-red-100);
}

.data {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s2);
}

@media screen and (min-width: 768px) {
  .data {
    flex: 2;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.graph {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  align-items: center;
  margin-bottom: var(--spacing-m);
  flex: 1;
  min-width: 300px;
}

.graphData {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s2);
  justify-content: space-between;
  width: 80%;
}

.url {
  color: var(--color-blue-100);
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
}

.urlDisabled {
  color: var(--color-grey-400);
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
}

@media screen and (min-width: 768px) {
  .header {
    justify-content: flex-start;
  }

  .summaryDetails {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-m);
    justify-content: space-between;
  }


  .footer {
    display: flex;
    justify-content: flex-end;
  }

  .rangeSliderAmount {
    display: inline;
  }
}
