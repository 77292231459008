/* Text font sizes */
.text-5xl {
  font-size: var(--font-size-h1);
}

.text-4xl {
  font-size: var(--font-size-h2);
}

.text-3xl {
  font-size: var(--font-size-h3);
}

.text-2xl {
  font-size: var(--font-size-h4);
}

.text-xl {
  font-size: var(--font-size-h5);
}

.text-lg {
  font-size: var(--font-size-h6);
}

.text-base {
  font-size: var(--font-size-b1);
}

.text-sm {
  font-size: var(--font-size-b2);
}

.text-xs {
  font-size: var(--font-size-b3);
}
