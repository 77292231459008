.container {
  text-align: center;
  max-width: 396px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading{
  font-size: var(--font-size-h3);
  line-height: var(--font-line-height-default);
}

.content {
  line-height: var(--font-line-height-default);
  margin-top: var(--spacing-m);
}

.url {
  color: var(--color-blue-100);
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 768px) {
  .heading {
    font-size: var(--font-size-h5);
  }
  .content {
    font-size: var(--font-size-b2);
  }
}