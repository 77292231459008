.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 57px 10px 23px 10px;
  width: 95%;
}

.titleHeading {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: var(--font-family-medium);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-h5);
  line-height: var(--font-line-height-default);
  color: var(--color-grey-700);
  margin: 0 0 20px 0;
  width: inherit;
}

.titleSubHeading {
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--color-grey-700);
  font-size: var(--font-size-b1);
  width: inherit;
}

@media (min-width: 768px) {
  .titleHeading {
    font-size: var(--font-size-h3);
  } 
}