.loginContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.loginFormWrapper {
  min-height: 470px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.heading {
  color: var(--color-grey-700);
}

.subHeading {
  margin-top: 10px;
}

.formik {
  height: auto;
  width: 100%;
  margin-top: 28px;
}

.terms {
  margin: 60px 0 120px 0;
  color: var(--color-grey-500);
  font-size: var(--font-size-b2);
  text-align: center;
  padding: 0 20px 0 20px;
}


.boldRegistrationLink {
  color: var(--color-red-100);
  cursor: pointer;
  font-family: var(--font-family-bold);
}

.formContainerInput {
  display: flex;
  justify-content: center;
  max-width: 100%;
  width: 354px;

  
}

@media (min-width: 768px) {
  .titleHeading {
    font-size: var(--font-size-h3);
  } 
}