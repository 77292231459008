/* Font sizes - Heading */
.heading-1 {
  font-size: var(--font-size-h1);
}

.heading-2 {
  font-size: var(--font-size-h2);
}

.heading-3 {
  font-size: var(--font-size-h3);
}

.heading-4 {
  font-size: var(--font-size-h4);
}

.heading-5 {
  font-size: var(--font-size-h5);
}

.heading-6 {
  font-size: var(--font-size-h6);
}