.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(0, 0%, 0%, 0.5);
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-m);
  max-height: 100vh;
}

.dialog {
  background: var(--color-white);
  border-radius: var(--radius-l);
  overflow: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
