.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-bold);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-grey-025);
}

.row:nth-child(odd) {
  background-color: var(--color-white);
}

.col {
  padding: var(--spacing-s1);
}

.url {
  color: var(--color-blue-100);
  text-decoration: underline;
  cursor: pointer;
  font-weight: var(--font-weight-bold);
  background: none;
  border: none;
  font-size: var(--font-size-b1);
}

.actionBar {
  text-align: right;
}

.inline {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);
}
