.container {
  margin: 0 var(--gutter-mobile);
}

@media screen and (min-width: 768px) {
  .container {
    margin: 0 var(--gutter-tablet);
  }
}

@media screen and (min-width: 1440px) {
  .container {
    margin: 0 var(--gutter-desktop);
    max-width: var(--container-max-width);
  }
}