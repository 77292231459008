.title {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-medium);
  text-align: center;
  line-height: 150%;
  margin-top: var(--spacing-m);
}

.header {
  margin-bottom: var(--spacing-m);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-m);
}

.body {
  overflow-y: auto;
  max-height: calc(100vh - (4 * var(--spacing-m)));
  text-align: center;
}

.footer {
  margin-top: var(--spacing-m);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--spacing-m);
  width: 100%;
}

.divider {
  width: var(--spacing-m);
  border: 3px solid var(--color-red-100);
  border-radius: 3px;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--spacing-m) var(--spacing-l2);
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.modal {
  max-height: 80%;
  padding: var(--spacing-xl);
  max-width: var(--breakpoint-tablet);
}

.icon {
  padding: var(--spacing-s2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgIcon {
  min-width: 58px;
  height: 57px;
}

@media (max-width: 576px) {
  .modal {
    max-height: 90%;
    padding: var(--spacing-m);
    max-width: var(--breakpoint-mobile);
  }
  .title {
    font-size: var(--font-size-h5);
    margin-top: 0;
  }
  .close {
    padding: var(--spacing-s2);
  }
}