.container {
  overflow: auto;
  max-height: 450px;
  position: relative;
  width: 100%;
  white-space: nowrap;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.header {
  background: var(--color-grey-100);
  position: sticky;
  top: 0;
}

.header .cell {
  text-align: left;
  border: none;
  font-weight: var(--font-weight-regular);
  font-family: RobotoMedium, sans-serif;
  border-bottom: 1px solid var(--color-grey-400);
  margin: 0;
}

.header .cell, .footer .cell {
  white-space: nowrap;
}

.header .cell, .cell, .footer .cell {
  padding: var(--spacing-s1) var(--spacing-s1);
}

.row {
  margin: var(--spacing-xs1) 0;
}

.row:nth-child(even) {
  background: var(--color-grey-025);
}

.body {
  background: var(--color-white);
}

.body .cell {
  border-bottom: 1px solid var(--color-grey-300);
}

.footer {
  position: sticky;
  bottom: 0;
  background: var(--color-white);
}

.footer .cell {
  border-top: 1px solid var(--color-grey-300);
}

@supports selector(::-webkit-scrollbar)
  and selector(::-webkit-scrollbar-track)
  and selector(::-webkit-scrollbar-thumb)
{

  /* width */
  .container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  .container::-webkit-scrollbar-track {
    background: var(--color-grey-300);
    margin: 46px 0 0;
    border-radius: 3px;
  }

  /* Handle */
  .container::-webkit-scrollbar-thumb {
    background: var(--color-red-100);
    border-radius: 3px;
  }
}
