/* Text colors */
.color-white {
  color: var(--color-white);
}

.color-grey-025 {
  color: var(--color-grey-025);
}

.color-grey-50 {
  color: var(--color-grey-50);
}

.color-grey-100 {
  color: var(--color-grey-100);
}

.color-grey-200 {
  color: var(--color-grey-200);
}

.color-grey-300 {
  color: var(--color-grey-300);
}

.color-grey-400 {
  color: var(--color-grey-400);
}

.color-grey-500 {
  color: var(--color-grey-500);
}

.color-grey-600 {
  color: var(--color-grey-600);
}

.color-grey-700 {
  color: var(--color-grey-700);
}

.color-grey-800 {
  color: var(--color-grey-800);
}

.color-grey-900 {
  color: var(--color-grey-900);
}

.color-black {
  color: var(--color-black);
}

.color-red-020 {
  color: var(--color-red-020);
}

.color-red-040 {
  color: var(--color-red-040);
}

.color-red-060 {
  color: var(--color-red-060);
}

.color-red-100 {
  color: var(--color-red-100);
}

.color-red-200 {
  color: var(--color-red-200);
}

.color-red-300 {
  color: var(--color-red-300);
}

.color-blue-020 {
  color: var(--color-blue-020);
}

.color-blue-040 {
  color: var(--color-blue-040);
}

.color-blue-060 {
  color: var(--color-blue-060);
}

.color-blue-100 {
  color: var(--color-blue-100);
}

.color-blue-200 {
  color: var(--color-blue-200);
}

.color-blue-300 {
  color: var(--color-blue-300);
}

.color-blue-400 {
  color: var(--color-blue-400);
}

.color-purple-020 {
  color: var(--color-purple-020);
}

.color-purple-040 {
  color: var(--color-purple-040);
}

.color-purple-060 {
  color: var(--color-purple-060);
}

.color-purple-100 {
  color: var(--color-purple-100);
}

.color-purple-200 {
  color: var(--color-purple-200);
}

.color-green-020 {
  color: var(--color-green-020);
}

.color-green-040 {
  color: var(--color-green-040);
}

.color-green-060 {
  color: var(--color-green-060);
}

.color-green-100 {
  color: var(--color-green-100);
}

.color-green-200 {
  color: var(--color-green-200);
}

.color-yellow-020 {
  color: var(--color-yellow-020);
}

.color-yellow-040 {
  color: var(--color-yellow-040);
}

.color-yellow-060 {
  color: var(--color-yellow-060);
}

.color-yellow-100 {
  color: var(--color-yellow-100);
}

.color-orange-100 {
  color: var(--color-orange-100);
}
