   .summaryContainer {
    display: flex;
    width: 95%;
    padding: 0 var(--spacing-m);
    margin: 20px 0 0 0;
    justify-content: space-between;
    align-items: stretch;
    gap: var(--spacing-xl);
    flex-wrap: wrap;
  }
  
  .summaryProgressBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    flex-grow: 1;
    flex: 1 1;
  }
  
  .summaryProgressBarInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
    padding: 20px 0 0 0;
    min-width: 300px;
  }
  
  .summarySubContainerInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 2;
    gap: var(--spacing-s2);
  }
  
  .progressIndicator {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
  }
  
  .blueEllipse,
  .redEllipse {
    height: 10px;
    width: 10px;
    border-radius: 20px;
    margin: 0 10px 0 0;
  }
  
  .blueEllipse {
    background: var(--color-blue-100)
  }
  
  .redEllipse {
    background: var(--color-red-100)
  }
  
  .progressLabel {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    color: var(--color-grey-600); 
    font-size: var(--font-size-b2);
  }
  
  .progressLabelAmt {
    font-family: var(--font-weight-medium);
    font-size: var(--font-size-b2);
    color: var(--color-grey-600);
  }
  
  .description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .descriptionLabel {
    font-family: var(--font-family-medium);
    font-size: var(--font-size-b1);
    color: var(--color-grey-700);
  }
  
  .descriptionValue {
    font-family: var( --font-family-black);
    font-size: var(--font-size-b1);
    color: var(--color-grey-600);
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 184px);
    word-break: break-all;
  }
  
  .arrearsAmt {
    font-family: var( --font-family-black);
    font-size: var(--font-size-b1);
    line-height: 24px;
    color: var(--color-red-100);
  }

  @media screen and (min-width: 1024px) {

    .summaryProgressBarInfo {
      width: 95%;
    }
  }
